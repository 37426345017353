<template>
    <div>
        <v-row>
            <v-col cols="4">
                <v-select
                        :items="['Name','State','Phone']"
                        label="Filters"
                        outlined
                        dense
                        multiple
                        required
                        chips
                        v-model="filterItems"
                        prepend-icon=""
                        append-icon="mdi-filter"
                ></v-select>
            </v-col>
            <v-col cols="4" v-show="filterItems.includes('Name')">
                <v-text-field
                        outlined
                        dense
                        v-model="filterByName"
                        required
                        label="Name"
                        append-icon="mdi-package"
                ></v-text-field>
            </v-col>
            <v-col cols="4" v-show="filterItems.includes('State')">
                <v-text-field
                        outlined
                        dense
                        v-model="filterByState"
                        required
                        label="State"
                        append-icon="mdi-package"
                ></v-text-field>
            </v-col>
            <v-col cols="4" v-show="filterItems.includes('Phone')">
                <v-text-field
                        outlined
                        dense
                        v-model="filterByPhone"
                        required
                        label="Mobile Number"
                        append-icon="mdi-package"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-dialog persistent v-model="dialog" max-width="500px">
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-tabs
                            color="primary"
                    >
                        <v-tab>Buisness</v-tab>
                        <v-tab>Political</v-tab>
                        <v-tab>NGO</v-tab>

                        <v-tab-item
                                :key="1"
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        Name :
                                        <div class="font-weight-black">
                                            {{editedItem.customer_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        State :
                                        <div class="font-weight-black">
                                            {{editedItem.state}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        Package :
                                        <div class="font-weight-black">
                                            {{editedItem.package_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        Credits :
                                        <div class="font-weight-black">
                                            {{editedItem.available_credits}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        Buisness Designation :
                                        <div class="font-weight-black">
                                            {{editedItem.business_designation}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        Buisness Organisation Name:
                                        <div class="font-weight-black">
                                            {{editedItem.business_organisation_name}}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item
                                :key="2"
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        Name :
                                        <div class="font-weight-black">
                                            {{editedItem.customer_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        State :
                                        <div class="font-weight-black">
                                            {{editedItem.state}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        Package :
                                        <div class="font-weight-black">
                                            {{editedItem.package_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        Credits :
                                        <div class="font-weight-black">
                                            {{editedItem.available_credits}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        Political Party Name :
                                        <div class="font-weight-black">
                                            {{editedItem.political_party_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        Political Designation :
                                        <div class="font-weight-black">
                                            {{editedItem.political_designation}}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>

                        <v-tab-item
                                :key="3"
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        Name :
                                        <div class="font-weight-black">
                                            {{editedItem.customer_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        State :
                                        <div class="font-weight-black">
                                            {{editedItem.state}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        Package :
                                        <div class="font-weight-black">
                                            {{editedItem.package_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        Credits :
                                        <div class="font-weight-black">
                                            {{editedItem.available_credits}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        NGO Name :
                                        <div class="font-weight-black">
                                            {{editedItem.ngo_name}}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        NGO Designation :
                                        <div class="font-weight-black">
                                            {{editedItem.ngo_designation}}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs>

                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
                :headers="headers"
                :items="filterFinalData"
                sort-by="calories"
                class="elevation-3"
                :search="search"
        >

            <template v-slot:item.Business_Designation="{item}">
                <span v-if="item.business_designation"> {{item.business_designation}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.Available_Credits="{item}">
                <span v-if="item.available_credits"> {{item.available_credits}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.Package_Name="{item}">
                <span v-if="item.package_name"> {{item.package_name}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.Customer_Name="{item}">
                <span v-if="item.customer_name"> {{item.Customer_Name}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.state="{item}">
                <span v-if="item.state"> {{item.state}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.Customer_Phone="{item}">
                <span v-if="item.customer_phone"> {{item.customer_phone}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.package_validity="{item}">

                <span v-if="item.package_validity"> {{moment(item.package_validity).format("DD/MM/YYYY")}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.political_designation="{item}">
                <span v-if="item.political_designation"> {{item.political_designation}} </span>
                <span v-else> - </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 blue--text" @click="editItem(item)">
                    mdi-eye
                </v-icon>
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="secondary"
                ></v-progress-circular>
                <v-icon
                        v-else
                        x-large
                        color="grey lighten-1"
                >
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {validationMixin} from 'vuelidate'
    import * as moment from 'moment'

    export default {
        name: 'Customers',
        mixins: [validationMixin],
        data: () => ({
            headers: [
                {text: 'Name', value: 'customer_name'},
                {text: 'Phone', value: 'customer_phone'},
                {text: 'Package', value: 'package_name'},
                {text: 'Credits', value: 'available_credits'},
                {text: 'Validity', value: 'package_validity'},
                {text: 'State', value: 'state'},
                {text: 'City', value: 'city'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            search: '',
            data: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            editedItem: {
                //id:null,
                full_name: '',
                package_id: null,
                available_credits: '',
                state: '',
                business_designation: '',
                political_designation: '',
            },
            defaultItem: {
                // id:null,
                full_name: '',
                package_id: null,
                available_credits: '',
                state: '',
                business_designation: '',
                political_designation: '',
            },
            filterItems: [],
            filterByName: '',
            filterByState: '',
            filterByPhone: ''
        }),
        mounted() {
            this.getCustomers();
        },
        methods: {
            moment,
            editItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.img_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deleteCustomer(this.editedItem.Customer_id)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.editedIndex == -1 ? this.addCustomer() : this.updateCustomer()
            },
            //Customers API Calls
            getCustomers() {
                this.loading = true
                this.$Axios
                    .get('get-customer-details')
                    .then((res) => {
                        this.data = res.data.data;
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            deleteCustomer(id) {
                console.log("Selected id : ", id)
                this.$Axios
                    .post('customer/delete-customer?id=' + id)
                    .then(() => {
                        this.getCustomers();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
        },
        computed: {
            filterFinalData() {
                if (this.filterItems.length === 0) {
                    return this.data
                } else {
                    //   //moment(this.editedItem.date).format('YYYY-MM_DD')
                    let data = this.data
                    if (this.filterItems.includes('Name') && this.filterByName) {
                        data = data.filter((data) => data.customer_name?.toLowerCase()?.includes(this.filterByName.toLowerCase()))
                    }
                    if (this.filterItems.includes('State') && this.filterByState) {
                        data = data.filter((data) => data.state?.toLowerCase()?.includes(this.filterByState.toLowerCase()))
                    }
                    if (this.filterItems.includes('Phone') && this.filterByPhone) {
                        data = data.filter((data) => data.customer_phone?.toLowerCase()?.includes(this.filterByPhone.toLowerCase()))
                    }
                    return data
                }
            },
            formTitle() {
                return this.editedIndex === -1 ? 'New Customer' : 'Customer Details';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },

        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
    };
</script>